
import Vue from "vue"
import permissionMixin from "@/mixins/PermissionMixin.vue"
import PositionsAutocompleteMixin from "@/mixins/PositionsAutocompleteMixin.vue"
import { state } from "@/plugins/state"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"
import SelectSpace from "@/components/SelectSpace.vue"

export default Vue.extend({
  components: { SelectSpace },
  name: 'Multiposting',
  mixins: [
    permissionMixin,
    PositionsAutocompleteMixin,
  ],
  data: () => ({
    url: "https://app.mytalentplug.com/",
    overlay: true,
    loading: false,
    spaceId: null,
    selectedPositions: null
  }),
  computed: {
    spaceName: function(): string {
      return state.spaceName ?? ""
    },
  },
  methods: {
    async createMultipostingOffer() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.loading = true
        axios
          .post(`/v1/multiposting/${this.selectedPositions}`)
          .then((response: AxiosResponse) => {
              this.url = response.data
              this.overlay = false
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error})
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
})
